import React from 'react';
import Helmet from "react-helmet";
import Layout from '../components/layout';
import ListCases from '../components/listCases';
import FadeText from '../components/fadeText';
import Footer from '../components/footer';
import ContactBlock from '../components/contactBlock';
import JobsBlock from '../components/jobsBlock';

import { ThemeContext } from '../context/theme-context';

const CasesPage = ({ location }) => {

    return (

        <Layout bg="black" location={location}>

            <Helmet>
                <title>ATUO - Cases</title>
            </Helmet>

            <div className="container">
                <div className="size-hero grid content-center py-60 md:py-80">
                    <h1 className="text-7xl md:text-9xl">cases</h1>
                </div>

                <div className="mb-24 md:mb-40 text-center">
                    <p className="flex justify-center flex-col md:block text-4xl leading-normal mb-24 md:mb-40">
                        PROVOCAÇÃO <span className="text-yellow">+</span> CRIATIVIDADE <span className="text-yellow">+</span> ESTRATÉGIA
                    </p>
                    <p className="text-xl md:px-80">
                        Marcas que se conectaram com seus públicos conquistaram relevância e destaque.
                    </p>
                </div>
            </div>

            <ListCases />

            <FadeText />

            <ThemeContext.Consumer>
                {(cx) => (
                    <ContactBlock
                        weather={cx?.cookies?.weather}
                    />
                )}
            </ThemeContext.Consumer>

            <JobsBlock />

            <Footer bg="black" />

        </Layout>
    );
};

export default CasesPage;
